// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const uk: RequiredTranslationsTemplate = {
  meta: { localeName: 'Українська' },
  home: {
    title: 'ВІДКЛИКАННЯ ПРОДУКЦІЇ СНІГОступи SH500L MOUNTAIN',
    description:
      'Ви придбали пару снігоступів SH500L Montagne, і ми дякуємо вам за це. Ми виявили дефект у гвинтах, який може перешкоджати вашому просуванню на засніжених стежках. Тому просимо надати наступну інформацію. Вам буде надіслано посилку, щоб ви могли повернути свій продукт нашому постачальнику послуг FANDI, відповідальному за ремонт.',
    form: {
      title: 'Будь ласка, введіть наступну інформацію',
      fields: {
        firstName: "Ім'я",
        lastName: 'Прізвище',
        email: 'Email / Електронна адреса',
        phone: 'Номер телефону',
        country: 'Країна',
        serialNumber: 'Номер партії',
        choose: 'Вибрати',
        nc: 'ІНШЕ (мого номера партії немає в цьому списку)',
        submit: 'Підтвердити',
        address: 'Адреса доставки посилки',
        addressAdditional: 'Додаткові адреси',
        city: 'Місто',
        zip: 'Поштовий індекс',
      },
      errors: { requiredField: "Це поле є обов'язковим" },
      confirmations: {
        defective: {
          title: 'Ваш шолом підпадає під відкликання.',
          p1: 'Просимо вас припинити його використання та звернутися до найближчого магазину Decathlon з вашим шоломом для відшкодування або заміни.',
          p2: 'Для отримання додаткової інформації рекомендуємо звернутися до центру обслуговування клієнтів, контактні дані якого вказані в отриманому вами електронному листі.',
        },
        good: {
          title: 'Ваш шолом не підпадає під відкликання.',
          p1: 'Підтверджуємо, що ваш шолом не підпадає під це відкликання, тому ви можете продовжувати безпечно його використовувати.',
          p2: 'Перепрошуємо за незручності.',
          p3: 'З повагою,',
          p4: 'Команда Decathlon',
        },
        resetForm: 'Я маю інший шолом для перевірки',
        exit: 'Вийти',
        findShop: 'Знайти мій магазин',
      },
    },
    findTheSerialNumber: {
      title: 'Де знайти номер партії?',
      subTitle: 'Номер партії знаходиться всередині шолома під подушкою в передній частині.',
    },
    description2:
      'Під відкликання підпадають лише певні номери партій, кольори та розміри. Запрошуємо вас перевірити номер партії на вашому шоломі, щоб дізнатися, чи підпадає ваш продукт під цю кампанію відкликання.',
  },
}

export default uk
