import { RequiredTranslationsTemplate } from '.'

const fr: RequiredTranslationsTemplate = {
  home: {
    title: 'RAPPEL PRODUIT RAQUETTES A NEIGE SH500L MONTAGNE',
    description:
      'Vous avez acheté une paire de raquette à neige SH500L Montagne et nous vous en remercions. Nous avons identifié un défaut sur la visserie pouvant vous freiner dans votre progression sur sentier enneigés. Par conséquent, merci de renseigner les éléments suivants. Un colis vous sera envoyé pour rééxpédier le produit chez notre prestataire FANDI chargé de la réparation',
    form: {
      title: 'Merci de saisir les informations suivantes',
      fields: {
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Adresse mail',
        phone: 'Numéro de Téléphone',
        country: 'Pays',
        serialNumber: 'N° de lot',
        choose: 'Choisir',
        nc: "AUTRE (mon numéro de lot n'est pas dans cette liste)",
        submit: 'Valider',
        address: 'Adresse de réception du colis',
        addressAdditional: "Complément d'adresse",
        city: 'Ville',
        zip: 'Code postal ',
      },
      errors: { requiredField: 'Ce champ est requis' },
      confirmations: {
        defective: {
          title: 'Votre casque est concerné.',
          p1: "Nous vous demandons de ne plus l'utiliser et de vous rapprocher du magasin Decathlon de votre choix, avec votre casque, afin de proceder au remboursement ou au remplacement de celui-ci.",
          p2: "Pour plus d'information, nous vous conseillons de vous rapprochez de votre centre de relation clients qui se trouve sur le mail que vous avez reçu.",
        },
        good: {
          title: "Votre casque n'est pas concerné.",
          p1: "Nous vous confirmons que votre casque n'est pas concerné par ce rappel, vous pouvez donc continuer à utiliser celui-ci en toute sécurité.",
          p2: 'Nous nous excusons pour la gêne occasionnée.',
          p3: 'Sportivement,',
          p4: 'Les équipes Décathlon',
        },
        resetForm: "J'ai un autre casque à vérifier",
        exit: 'Quitter',
        findShop: 'Trouver mon magasin',
      },
    },
    findTheSerialNumber: {
      title: 'Où trouver mon numéro de lot ?',
      subTitle:
        "Le numéro de lot se trouve à l'interieur du casque sous la mousse située à l'avant du casque.",
    },
    description2:
      'Seuls certains numéros de lot, de certaines couleurs et tailles, sont concernés. Nous vous invitons à sélectionner le n° de lot correspondant à celui indiqué sur votre casque afin de savoir si votre casque est concerné par cette campagne de rappel :',
  },
  meta: { localeName: 'Français' },
}

export default fr
