import React, { useState, useEffect } from 'react'
import { Field, Form, Formik } from 'formik'
import {
  Button,
  Card,
  Columns,
  Element,
  Image,
  Modal,
  Form as BulmaForm,
  Icon,
} from 'react-bulma-components'
import InputField from '../form/fields/input'
import SelectField from '../form/fields/select'
import { countriesAsSelectioOptions } from '../../constants/countries'
// @ts-expect-error
import helmetBatchImg from '../../assets/images/helmet-batch.png'
import { Translate, translate } from '../../utils/translations'
import { useTranslations } from '../../hooks/use-translations'
import { checkStatusDataCreationPayload } from '../../../../backend/src/services/product-items/product-items.schema'
import { phonePrefixesAsSelectOptions, getDefaultPhonePrefix } from '../../constants/phone-prefixes'

export interface CheckStatusFormProps {
  onSubmit(values: checkStatusDataCreationPayload): void
}

const initialValues = {
  requestor: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    phonePrefix: '',
    country: '',
    address: '',
    addressAdditional: '',
    zip: '',
    city: '',
  },
  batchNumber: '',
}

const CheckStatusForm: React.FC<CheckStatusFormProps> = ({ onSubmit }) => {
  const [showModal, setShowModal] = useState(false)

  const ERRORS = {
    REQUIRED_FIELD: translate('home.form.errors.requiredField'),
    UNKNOWN_SERIAL_NUMBER: translate('home.form.errors.unknownSerialNumber'),
    PROVIDE_6_NUMBERS: translate('home.form.errors.provide6Numbers'),
  }

  const { current: currentLocale } = useTranslations()

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors: any = {}
        const requestor = values.requestor || {}
        if (!requestor.firstName) errors['requestor.firstName'] = ERRORS.REQUIRED_FIELD
        if (!requestor.lastName) errors['requestor.lastName'] = ERRORS.REQUIRED_FIELD
        if (!requestor.email || !requestor.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,12}$/))
          errors['requestor.email'] = ERRORS.REQUIRED_FIELD
        if (!requestor.country) errors['requestor.country'] = ERRORS.REQUIRED_FIELD
        if (!requestor.address) errors['requestor.address'] = ERRORS.REQUIRED_FIELD
        if (!requestor.zip) errors['requestor.zip'] = ERRORS.REQUIRED_FIELD
        if (!requestor.city) errors['requestor.city'] = ERRORS.REQUIRED_FIELD
        if (!requestor.phone) errors['requestor.phone'] = ERRORS.REQUIRED_FIELD
        if (!requestor.phonePrefix) errors['requestor.phonePrefix'] = ERRORS.REQUIRED_FIELD
        //TODO: serial number family
        console.log('errors', errors, values)
        return errors
      }}
      onSubmit={values => {
        console.log('values', values)
        setIsSubmitting(true)
        onSubmit({
          ...values,
          requestor: {
            ...values.requestor,
            language: currentLocale!,
          },
        })
      }}
    >
      {({ values, setFieldValue, errors }) => {
        // Mettre à jour le préfixe téléphonique lorsque le pays change
        useEffect(() => {
          if (values.requestor.country) {
            const newPrefix = getDefaultPhonePrefix(values.requestor.country)
            setFieldValue('requestor.phonePrefix', newPrefix)
          }
        }, [values.requestor.country, setFieldValue])

        return (
          <Form noValidate>
            {/*<FormikObserver values={props.values} onChange={values => onFormChange(values)} /> */}
            <Columns centered>
              <Columns.Column tablet={{ size: 12 }} desktop={{ size: 12 }} widescreen={{ size: 8 }}>
                <Card>
                  <Card.Content>
                    <Field
                      name="requestor.firstName"
                      label={translate('home.form.fields.firstName')}
                      component={InputField}
                    />
                    <Field
                      name="requestor.lastName"
                      label={translate('home.form.fields.lastName')}
                      component={InputField}
                    />

                    <Field
                      name="requestor.email"
                      label={translate('home.form.fields.email')}
                      component={InputField}
                      type="email"
                    />

                    <BulmaForm.Field>
                      <BulmaForm.Label>{translate('home.form.fields.phone')}</BulmaForm.Label>
                      <Columns>
                        <Columns.Column size={4}>
                          <Field
                            name="requestor.phonePrefix"
                            component={SelectField}
                            options={phonePrefixesAsSelectOptions}
                            selectDefaultChooseOptionLabel={translate('home.form.fields.choose')}
                          />
                        </Columns.Column>
                        <Columns.Column>
                          <Field
                            name="requestor.phone"
                            component={InputField}
                            type="tel"
                            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                            showLabel={false}
                          />
                        </Columns.Column>
                      </Columns>
                    </BulmaForm.Field>

                    <Field
                      name="requestor.country"
                      label={translate('home.form.fields.country')}
                      component={SelectField}
                      options={countriesAsSelectioOptions}
                      selectDefaultChooseOptionLabel={translate('home.form.fields.choose')}
                    />
                    <Field
                      name="requestor.address"
                      label={translate('home.form.fields.address')}
                      component={InputField}
                    />
                    <Field
                      name="requestor.addressAdditional"
                      label={translate('home.form.fields.addressAdditional')}
                      component={InputField}
                    />
                    <Field
                      name="requestor.city"
                      label={translate('home.form.fields.city')}
                      component={InputField}
                    />
                    <Field
                      name="requestor.zip"
                      label={translate('home.form.fields.zip')}
                      component={InputField}
                    />

                    <Button
                      fullwidth
                      color="primary"
                      style={{ marginTop: 'auto' }}
                      textWeight="bold"
                      mt={4}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <Translate value="home.form.fields.submit" />
                    </Button>
                  </Card.Content>
                </Card>
              </Columns.Column>
            </Columns>
            <FindSerialNumberModal show={showModal} onClose={() => setShowModal(false)} />
          </Form>
        )
      }}
    </Formik>
  )
}

export default CheckStatusForm

const FindSerialNumberModal: React.FC<{ show: boolean; onClose: () => void }> = ({
  show,
  onClose,
}) => {
  return (
    <Modal closeOnBlur closeOnEsc showClose show={show} onClose={onClose}>
      <Modal.Card>
        <Modal.Card.Header backgroundColor="white" style={{ borderBottom: 'none' }}>
          <Modal.Card.Title mobile={{ textSize: 6 }}>
            <Translate value="home.findTheSerialNumber.title" />
          </Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body style={{ paddingTop: 0 }}>
          <Element mb={2}>
            <Translate value="home.findTheSerialNumber.subTitle" />
          </Element>
          <Image src={helmetBatchImg} style={{ maxWidth: 800, margin: '0 auto' }} />
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  )
}
