// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const sl: RequiredTranslationsTemplate = {
  meta: { localeName: 'Slovenščina' },
  home: {
    title: 'ODPOKLIC IZDELKA: SNEŽNE KRPLJE SH500L',
    description:
      'Kupili ste snežne krplje SH500L, za kar smo vam hvaležni; a odkrili smo potencialno težavo z vijaki na izdelku, ki bi lahko oteževali hojo po zasneženem terenu. Zato vas prosimo, da nam zaupate svoje podatke, in poslali vam bomo paket, s katerim nam boste izdelek vrnili; mi pa ga bomo za vas popravili pri pooblaščenem serviserju.',
    form: {
      title: 'Prosimo, vnesite naslednje podatke',
      fields: {
        serialNumber: 'Številka serije',
        firstName: 'Ime',
        lastName: 'Priimek',
        email: 'E-poštni naslov',
        phone: 'Telefonska številka',
        address: 'Naslov za pošiljanje paketa',
        addressAdditional: 'Dodatni podatki o naslovu',
        city: 'Kraj',
        zip: 'Poštna številka',
        country: 'Država',
        submit: 'Potrdi',
        choose: 'Izberi',
        nc: 'DRUGO (moje številke serije ni na seznamu)',
      },
      errors: { requiredField: 'Polje je obvezno' },
      confirmations: {
        good: {
          title: 'Vaša čelada ni del odpoklica.',
          p1: 'Potrjujemo, da vaša čelada ni del tega odpoklica in jo lahko še naprej varno uporabljate.',
          p2: 'Opravičujemo se vam za morebitne nevšečnosti.',
          p3: 'Lep pozdrav,',
          p4: 'Ekipa Decathlon',
        },
        defective: {
          title: 'Vaša čelada je del tega odpoklica.',
          p1: 'Prosimo, nemudoma nehajte uporabljati čelado in jo prinesite v najbližjo Decathlonovo poslovalnico, kjer vam bomo vrnili denar ali zamenjali izdelek.',
          p2: 'Če bi želeli izvedeti več, se obrnite na center za podporo strankam. Kontaktne podatke najdete v e-poštnem sporočilu, ki ste ga prejeli.',
          p3: 'Nájdite najbližšiu predajňu Decathlon',
          decathlonLocatorLink: 'https://www.decathlon.sk/content/24-nase-predajne-decathlon',
          p4: 'Nižšie nájdete dokument „Oznámenie o vrátení produktu“, ktorý si, prosím, prineste so sebou:',
          p5: 'Pre viac informácii kontaktujte naše zákaznícke centrum, ktoré je Vám k dispozícii na telefónnom čísle +421 2 221 16 888 a to od pondelka do piatka (od 9h do 18h) a tiež cez víkend (od 9h do 17h).',
        },
        resetForm: 'Želim preveriti še eno čelado.',
        exit: 'Izhod',
        findShop: 'Najdi trgovino',
      },
    },
    findTheSerialNumber: {
      title: 'Kje najdem številko serije?',
      subTitle:
        'Številka serije je natisnjena v notranjosti čelade pod blazinicami na sprednjem delu.',
    },
    description2:
      'Napaka se pojavlja le pri določeni seriji, barvi in velikosti izdelka. Prosimo, preverite številko serije na svojem izdelku in se prepričajte, ali je vaša čelada del tega odpoklica.',
  },
}

export default sl
