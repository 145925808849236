/**
 * Liste complète des préfixes téléphoniques internationaux
 * Format: { [code_pays]: { prefix: string, name: string } }
 */
export const phonePrefixes: { [key: string]: { prefix: string; name: string } } = {
  // Europe
  AL: { prefix: '+355', name: 'Albania' },
  AD: { prefix: '+376', name: 'Andorra' },
  AT: { prefix: '+43', name: 'Austria' },
  BY: { prefix: '+375', name: 'Belarus' },
  BE: { prefix: '+32', name: 'Belgium' },
  BA: { prefix: '+387', name: 'Bosnia and Herzegovina' },
  BG: { prefix: '+359', name: 'Bulgaria' },
  HR: { prefix: '+385', name: 'Croatia' },
  CY: { prefix: '+357', name: 'Cyprus' },
  CZ: { prefix: '+420', name: 'Czech Republic' },
  DK: { prefix: '+45', name: 'Denmark' },
  EE: { prefix: '+372', name: 'Estonia' },
  FI: { prefix: '+358', name: 'Finland' },
  FR: { prefix: '+33', name: 'France' },
  DE: { prefix: '+49', name: 'Germany' },
  GR: { prefix: '+30', name: 'Greece' },
  HU: { prefix: '+36', name: 'Hungary' },
  IS: { prefix: '+354', name: 'Iceland' },
  IE: { prefix: '+353', name: 'Ireland' },
  IT: { prefix: '+39', name: 'Italy' },
  LV: { prefix: '+371', name: 'Latvia' },
  LI: { prefix: '+423', name: 'Liechtenstein' },
  LT: { prefix: '+370', name: 'Lithuania' },
  LU: { prefix: '+352', name: 'Luxembourg' },
  MT: { prefix: '+356', name: 'Malta' },
  MD: { prefix: '+373', name: 'Moldova' },
  MC: { prefix: '+377', name: 'Monaco' },
  ME: { prefix: '+382', name: 'Montenegro' },
  NL: { prefix: '+31', name: 'Netherlands' },
  MK: { prefix: '+389', name: 'North Macedonia' },
  NO: { prefix: '+47', name: 'Norway' },
  PL: { prefix: '+48', name: 'Poland' },
  PT: { prefix: '+351', name: 'Portugal' },
  RO: { prefix: '+40', name: 'Romania' },
  RU: { prefix: '+7', name: 'Russia' },
  SM: { prefix: '+378', name: 'San Marino' },
  RS: { prefix: '+381', name: 'Serbia' },
  SK: { prefix: '+421', name: 'Slovakia' },
  SI: { prefix: '+386', name: 'Slovenia' },
  ES: { prefix: '+34', name: 'Spain' },
  SE: { prefix: '+46', name: 'Sweden' },
  CH: { prefix: '+41', name: 'Switzerland' },
  UA: { prefix: '+380', name: 'Ukraine' },
  GB: { prefix: '+44', name: 'United Kingdom' },
  VA: { prefix: '+379', name: 'Vatican City' },

  // Amérique du Nord et Centrale
  US: { prefix: '+1', name: 'United States' },
  CA: { prefix: '+1', name: 'Canada' },
  MX: { prefix: '+52', name: 'Mexico' },
  BS: { prefix: '+1242', name: 'Bahamas' },
  BB: { prefix: '+1246', name: 'Barbados' },
  BZ: { prefix: '+501', name: 'Belize' },
  CR: { prefix: '+506', name: 'Costa Rica' },
  CU: { prefix: '+53', name: 'Cuba' },
  DM: { prefix: '+1767', name: 'Dominica' },
  DO: { prefix: '+1809', name: 'Dominican Republic' },
  SV: { prefix: '+503', name: 'El Salvador' },
  GD: { prefix: '+1473', name: 'Grenada' },
  GT: { prefix: '+502', name: 'Guatemala' },
  HT: { prefix: '+509', name: 'Haiti' },
  HN: { prefix: '+504', name: 'Honduras' },
  JM: { prefix: '+1876', name: 'Jamaica' },
  NI: { prefix: '+505', name: 'Nicaragua' },
  PA: { prefix: '+507', name: 'Panama' },
  PR: { prefix: '+1787', name: 'Puerto Rico' },
  KN: { prefix: '+1869', name: 'Saint Kitts and Nevis' },
  LC: { prefix: '+1758', name: 'Saint Lucia' },
  VC: { prefix: '+1784', name: 'Saint Vincent and the Grenadines' },
  TT: { prefix: '+1868', name: 'Trinidad and Tobago' },

  // Amérique du Sud
  AR: { prefix: '+54', name: 'Argentina' },
  BO: { prefix: '+591', name: 'Bolivia' },
  BR: { prefix: '+55', name: 'Brazil' },
  CL: { prefix: '+56', name: 'Chile' },
  CO: { prefix: '+57', name: 'Colombia' },
  EC: { prefix: '+593', name: 'Ecuador' },
  GY: { prefix: '+592', name: 'Guyana' },
  PY: { prefix: '+595', name: 'Paraguay' },
  PE: { prefix: '+51', name: 'Peru' },
  SR: { prefix: '+597', name: 'Suriname' },
  UY: { prefix: '+598', name: 'Uruguay' },
  VE: { prefix: '+58', name: 'Venezuela' },

  // Asie
  AF: { prefix: '+93', name: 'Afghanistan' },
  AM: { prefix: '+374', name: 'Armenia' },
  AZ: { prefix: '+994', name: 'Azerbaijan' },
  BH: { prefix: '+973', name: 'Bahrain' },
  BD: { prefix: '+880', name: 'Bangladesh' },
  BT: { prefix: '+975', name: 'Bhutan' },
  BN: { prefix: '+673', name: 'Brunei' },
  KH: { prefix: '+855', name: 'Cambodia' },
  CN: { prefix: '+86', name: 'China' },
  GE: { prefix: '+995', name: 'Georgia' },
  IN: { prefix: '+91', name: 'India' },
  ID: { prefix: '+62', name: 'Indonesia' },
  IR: { prefix: '+98', name: 'Iran' },
  IQ: { prefix: '+964', name: 'Iraq' },
  IL: { prefix: '+972', name: 'Israel' },
  JP: { prefix: '+81', name: 'Japan' },
  JO: { prefix: '+962', name: 'Jordan' },
  KZ: { prefix: '+7', name: 'Kazakhstan' },
  KW: { prefix: '+965', name: 'Kuwait' },
  KG: { prefix: '+996', name: 'Kyrgyzstan' },
  LA: { prefix: '+856', name: 'Laos' },
  LB: { prefix: '+961', name: 'Lebanon' },
  MY: { prefix: '+60', name: 'Malaysia' },
  MV: { prefix: '+960', name: 'Maldives' },
  MN: { prefix: '+976', name: 'Mongolia' },
  MM: { prefix: '+95', name: 'Myanmar' },
  NP: { prefix: '+977', name: 'Nepal' },
  KP: { prefix: '+850', name: 'North Korea' },
  OM: { prefix: '+968', name: 'Oman' },
  PK: { prefix: '+92', name: 'Pakistan' },
  PS: { prefix: '+970', name: 'Palestine' },
  PH: { prefix: '+63', name: 'Philippines' },
  QA: { prefix: '+974', name: 'Qatar' },
  SA: { prefix: '+966', name: 'Saudi Arabia' },
  SG: { prefix: '+65', name: 'Singapore' },
  KR: { prefix: '+82', name: 'South Korea' },
  LK: { prefix: '+94', name: 'Sri Lanka' },
  SY: { prefix: '+963', name: 'Syria' },
  TW: { prefix: '+886', name: 'Taiwan' },
  TJ: { prefix: '+992', name: 'Tajikistan' },
  TH: { prefix: '+66', name: 'Thailand' },
  TR: { prefix: '+90', name: 'Turkey' },
  TM: { prefix: '+993', name: 'Turkmenistan' },
  AE: { prefix: '+971', name: 'United Arab Emirates' },
  UZ: { prefix: '+998', name: 'Uzbekistan' },
  VN: { prefix: '+84', name: 'Vietnam' },
  YE: { prefix: '+967', name: 'Yemen' },

  // Afrique
  DZ: { prefix: '+213', name: 'Algeria' },
  AO: { prefix: '+244', name: 'Angola' },
  BJ: { prefix: '+229', name: 'Benin' },
  BW: { prefix: '+267', name: 'Botswana' },
  BF: { prefix: '+226', name: 'Burkina Faso' },
  BI: { prefix: '+257', name: 'Burundi' },
  CM: { prefix: '+237', name: 'Cameroon' },
  CV: { prefix: '+238', name: 'Cape Verde' },
  CF: { prefix: '+236', name: 'Central African Republic' },
  TD: { prefix: '+235', name: 'Chad' },
  KM: { prefix: '+269', name: 'Comoros' },
  CD: { prefix: '+243', name: 'Democratic Republic of the Congo' },
  DJ: { prefix: '+253', name: 'Djibouti' },
  EG: { prefix: '+20', name: 'Egypt' },
  GQ: { prefix: '+240', name: 'Equatorial Guinea' },
  ER: { prefix: '+291', name: 'Eritrea' },
  SZ: { prefix: '+268', name: 'Eswatini' },
  ET: { prefix: '+251', name: 'Ethiopia' },
  GA: { prefix: '+241', name: 'Gabon' },
  GM: { prefix: '+220', name: 'Gambia' },
  GH: { prefix: '+233', name: 'Ghana' },
  GN: { prefix: '+224', name: 'Guinea' },
  GW: { prefix: '+245', name: 'Guinea-Bissau' },
  CI: { prefix: '+225', name: 'Ivory Coast' },
  KE: { prefix: '+254', name: 'Kenya' },
  LS: { prefix: '+266', name: 'Lesotho' },
  LR: { prefix: '+231', name: 'Liberia' },
  LY: { prefix: '+218', name: 'Libya' },
  MG: { prefix: '+261', name: 'Madagascar' },
  MW: { prefix: '+265', name: 'Malawi' },
  ML: { prefix: '+223', name: 'Mali' },
  MR: { prefix: '+222', name: 'Mauritania' },
  MU: { prefix: '+230', name: 'Mauritius' },
  MA: { prefix: '+212', name: 'Morocco' },
  MZ: { prefix: '+258', name: 'Mozambique' },
  NA: { prefix: '+264', name: 'Namibia' },
  NE: { prefix: '+227', name: 'Niger' },
  NG: { prefix: '+234', name: 'Nigeria' },
  CG: { prefix: '+242', name: 'Republic of the Congo' },
  RW: { prefix: '+250', name: 'Rwanda' },
  ST: { prefix: '+239', name: 'São Tomé and Príncipe' },
  SN: { prefix: '+221', name: 'Senegal' },
  SC: { prefix: '+248', name: 'Seychelles' },
  SL: { prefix: '+232', name: 'Sierra Leone' },
  SO: { prefix: '+252', name: 'Somalia' },
  ZA: { prefix: '+27', name: 'South Africa' },
  SS: { prefix: '+211', name: 'South Sudan' },
  SD: { prefix: '+249', name: 'Sudan' },
  TZ: { prefix: '+255', name: 'Tanzania' },
  TG: { prefix: '+228', name: 'Togo' },
  TN: { prefix: '+216', name: 'Tunisia' },
  UG: { prefix: '+256', name: 'Uganda' },
  ZM: { prefix: '+260', name: 'Zambia' },
  ZW: { prefix: '+263', name: 'Zimbabwe' },

  // Océanie
  AU: { prefix: '+61', name: 'Australia' },
  FJ: { prefix: '+679', name: 'Fiji' },
  KI: { prefix: '+686', name: 'Kiribati' },
  MH: { prefix: '+692', name: 'Marshall Islands' },
  FM: { prefix: '+691', name: 'Micronesia' },
  NR: { prefix: '+674', name: 'Nauru' },
  NZ: { prefix: '+64', name: 'New Zealand' },
  PW: { prefix: '+680', name: 'Palau' },
  PG: { prefix: '+675', name: 'Papua New Guinea' },
  WS: { prefix: '+685', name: 'Samoa' },
  SB: { prefix: '+677', name: 'Solomon Islands' },
  TO: { prefix: '+676', name: 'Tonga' },
  TV: { prefix: '+688', name: 'Tuvalu' },
  VU: { prefix: '+678', name: 'Vanuatu' },
}

/**
 * Convertit les préfixes téléphoniques en options pour un menu déroulant
 * @returns Array d'options au format { value, label }
 */
export const phonePrefixesAsSelectOptions = Object.entries(phonePrefixes)
  .map(([countryCode, { prefix, name }]) => ({
    value: prefix,
    label: `${prefix} ${name}`,
  }))
  .sort((a, b) => a.label.localeCompare(b.label))

/**
 * Obtient le préfixe téléphonique par défaut en fonction du pays sélectionné
 * @param countryCode Code du pays
 * @returns Préfixe téléphonique ou '+33' par défaut
 */
export const getDefaultPhonePrefix = (countryCode: string): string => {
  return phonePrefixes[countryCode]?.prefix || '+33'
}
