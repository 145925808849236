import bg from './bg'
import cs from './cs'
import de from './de'
import en from './en'
import es from './es'
import fr from './fr'
import hr from './hr'
import it from './it'
import nl from './nl'
import hu from './hu'
import pl from './pl'
import ro from './ro'
import sl from './sl'
//import sv from './sv'
import uk from './uk'
//import hi from './hi'
import ms from './ms'

export default {
  en,
  fr,
  nl,
  de,
  bg,
  hr,
  cs,
  hu,
  it,
  pl,
  ro,
  sl,
  es,
  //sv,
  uk,
  //hi,
  ms,
}

export interface RequiredTranslationsTemplate {
  meta: {
    localeName: string
  }
  home: {
    title: string
    description: string
    description2: string
    form: {
      title: string
      fields: {
        serialNumber: string
        //serialNumberFamily: string
        firstName: string
        lastName: string
        email: string
        phone: string
        address: string
        addressAdditional: string
        city: string
        zip: string
        country: string
        submit: string
        choose: string
        nc: string
      }
      errors: {
        requiredField: string
        //unknownSerialNumber: string
        //provide6Numbers: string
      }
      confirmations: {
        good: {
          title: string
          p1: string
          p2: string
          p3: string
          p4: string
        }
        defective: {
          title: string
          p1: string
          p2: string
          //p3: string
          //decathlonLocatorLink: string
          //p4: string
          //p5: string
        }
        resetForm: string
        exit: string
        findShop: string
      }
    }
    findTheSerialNumber: {
      title: string
      subTitle: string
    }
  }
}

export type PartialTranslationsTemplate = DeepPartial<RequiredTranslationsTemplate>

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T
