// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const es: RequiredTranslationsTemplate = {
  meta: { localeName: 'Español' },
  home: {
    title: 'AVISO PRODUCTO: RAQUETAS DE NIEVE SH500 MOUNTAIN',
    description:
      'Ha adquirido un par de raquetas de nieve SH500L Mountain, y le damos las gracias por ello. Hemos detectado un defecto en los tornillos que podría dificultar su avance por senderos nevados. Por ello, le rogamos que nos facilite los siguientes datos. Se le enviará un paquete para que pueda devolver su producto a nuestro proveedor de servicios FANDI responsable de la reparación.',
    form: {
      title: 'Por favor, indica la información siguiente',
      fields: {
        firstName: 'Nombre',
        lastName: 'Apellido',
        email: 'Email / Correo electrónico',
        phone: 'Número de teléfono',
        address: 'Dirección para la entrega del paquete',
        addressAdditional: 'Datos adicionales para la dirección de entrega',
        city: 'Cuidad',
        zip: 'Código portal',
        country: 'País',
        serialNumber: 'N.° de lote',
        choose: 'Seleccionar',
        nc: 'OTRO (mi número de lote no está en la lista)',
        submit: 'Validar',
      },
      errors: { requiredField: 'Este campo es obligatorio' },
      confirmations: {
        defective: {
          title: 'Tu casco está afectado',
          p1: 'Te rogamos que dejes de utilizarlo y que acudas a la tienda Decathlon de tu elección, con tu casco, para que procedamos a su reembolso o sustitución.\n',
          p2: 'Para más información, te aconsejamos que contactes con el centro de atención al cliente, cuyos datos se\r\nencuentran en el correo electrónico que has recibido',
        },
        good: {
          title: 'Tu casco no está afectado',
          p1: 'Te confirmamos que tu casco no está afectado por este aviso de producto, así que puedes seguir usándolo con total seguridad',
          p2: 'Lamentamos las molestias que te hayamos podido ocasionar',
          p3: 'Deportivamente',
          p4: 'Los equipos de Decathlon',
        },
        resetForm: 'Tengo otro casco para verificar',
        exit: 'Salir',
        findShop: 'Encontrar mi tienda',
      },
    },
    findTheSerialNumber: {
      title: '¿Dónde puedo encontrar mi número de lote?',
      subTitle:
        'El número de lote se encuentra en el interior del casco, bajo el acolchado de la parte delantera',
    },
    description2:
      'Solo están afectados algunos números de lote, así que te\r\nrogamos que indiques las últimas cifras de tu n.° de lote para saber si tu casco está afectado por esta\r\ncampaña de retirada:',
  },
}

export default es
