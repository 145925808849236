// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const ms: RequiredTranslationsTemplate = {
  meta: { localeName: 'Bahasa Melayu' },
  home: {
    title: 'PRODUCT RECALL SNOWSHOES SH500L MOUNTAIN',
    description:
      'You have purchased a pair of SH500L Montagne snowshoes, and we thank you for that. We have identified a defect in the screws that could hinder your progress on snowy trails. Therefore, please provide the following details. A package will be sent to you so that you can return your product to our service provider responsible for the repair.',
    description2:
      'Hanya nombor kelompok, warna, dan saiz tertentu yang terjejas. Kami menjemput anda untuk memeriksa nombor kelompok pada topi keledar anda untuk melihat sama ada produk anda terjejas oleh kempen penarikan balik ini.',
    form: {
      title: 'Sila masukkan maklumat berikut',
      fields: {
        serialNumber: 'Nombor kelompok',
        firstName: 'First name',
        lastName: 'Surname',
        email: 'Mail adress',
        phone: 'Phone Number',
        address: 'Shipping address for the package',
        addressAdditional: 'Additional address details',
        city: 'City',
        zip: 'ZIP Code / Postal Code',
        country: 'Country',
        submit: 'Hantar',
        choose: 'Pilih',
        nc: 'LAIN-LAIN (nombor kelompok saya tidak ada dalam senarai ini)',
      },
      errors: { requiredField: 'Bidang ini diperlukan' },
      confirmations: {
        good: {
          title: 'Topi keledar anda tidak terjejas.',
          p1: 'Kami mengesahkan bahawa topi keledar anda tidak terjejas oleh penarikan balik ini, jadi anda boleh terus menggunakan produk anda dengan selamat.',
          p2: 'Kami memohon maaf atas sebarang kesulitan yang disebabkan.',
          p3: 'Salam hormat,',
          p4: 'Pasukan Decathlon',
        },
        defective: {
          title: 'Topi keledar anda terjejas.',
          p1: 'Kami meminta anda berhenti menggunakan produk ini dengan segera dan bawa topi keledar anda ke kedai Decathlon terdekat, untuk meneruskan dengan bayaran balik atau penggantian produk.',
          p2: 'Untuk maklumat lanjut, kami mengesyorkan anda menghubungi pusat perkhidmatan pelanggan anda. Butiran hubungan boleh didapati dalam e-mel yang anda terima.',
        },
        resetForm: 'Saya ada topi keledar lain untuk diperiksa.',
        exit: 'Keluar',
        findShop: 'Cari kedai saya',
      },
    },
    findTheSerialNumber: {
      title: 'Di mana saya boleh mencari nombor kelompok saya?',
      subTitle:
        'Nombor kelompok terletak di dalam topi keledar, di bawah pelapik di bahagian depan.',
    },
  },
}

export default ms
