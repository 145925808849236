// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const en: RequiredTranslationsTemplate = {
  meta: { localeName: 'English' },
  home: {
    title: 'PRODUCT RECALL SNOWSHOES SH500L MOUNTAIN',
    description:
      'You have purchased a pair of SH500L Montagne snowshoes, and we thank you for that. We have identified a defect in the screws that could hinder your progress on snowy trails. Therefore, please provide the following details. A package will be sent to you so that you can return your product to our service provider FANDI responsible for the repair.',
    form: {
      title: 'Please enter the following information',
      fields: {
        serialNumber: 'Batch number',
        firstName: 'First name',
        lastName: 'Surname',
        email: 'Mail adress',
        phone: 'Phone Number',
        address: 'Shipping address for the package',
        addressAdditional: 'Additional address details',
        city: 'City',
        zip: 'ZIP Code / Postal Code',
        country: 'Country',
        submit: 'Submit',
        choose: 'Choose',
        nc: 'OTHER (my batch number is not on this list)',
      },
      errors: { requiredField: 'This field is required' },
      confirmations: {
        good: {
          title: 'Your helmet is not affected.',
          p1: 'We confirm that your helmet is not affected by this recall, so you can continue to use your product safely.',
          p2: 'We apologise for any inconvenience caused.',
          p3: 'Best regards,',
          p4: 'Decathlon Team',
        },
        defective: {
          title: 'Your helmet is affected.',
          p1: 'We ask that you stop using your this product immediately and bring your helmet to your nearest Decathlon store, in order to proceed with a refund or replacement of the product.',
          p2: 'For more information, we recommend contacting your customer service center. Contact details can be found in the email you received.',
          p3: 'To find the closest Decathlon Workshop to you:',
          decathlonLocatorLink: 'https://www.decathlon.fr/store-locator',
          p4: 'You will find below your "Customer Return Notice" to present to our Technician when you return to the workshop:',
          p5: 'For more information, please contact our dedicated contact centre for this product recall, 0 805 38 38 49, free of charge from metropolitan France (open Monday to Friday from 9am to 7pm) or by email at rappel.decathlon@fandi.fr',
        },
        resetForm: 'I have another helmet to check.',
        exit: 'Exit',
        findShop: 'Find my store',
      },
    },
    findTheSerialNumber: {
      title: 'Where can I find my batch number?',
      subTitle: 'The batch number is located inside the helmet, beneath the padding at the front.',
    },
    description2:
      'Only certain batch numbers, colors, and sizes are affected. We invite you to check the batch number on your helmet to see if your product is affected by this recall campaign.',
  },
}

export default en
