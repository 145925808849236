// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const ro: RequiredTranslationsTemplate = {
  meta: { localeName: 'Romana' },
  home: {
    title: 'RECHEMARE PRODUS RĂCHETE DE ZĂPADĂ SH500L MOUNTAIN',
    description:
      'Ați achiziționat o pereche de rachete de zăpadă SH500L Mountain și vă mulțumim pentru aceasta. Am identificat un defect la șuruburi, care vă poate împiedica progresul pe traseele înzăpezite. Prin urmare, vă rugăm să furnizați următoarele detalii. Un pachet vă va fi trimis pentru a vă putea returna produsul furnizorului nostru de servicii FANDI responsabil cu reparația.',
    form: {
      title: 'Vă rugăm să introduceți următoarele informații',
      fields: {
        serialNumber: 'Număr lot',
        firstName: 'Prenume',
        lastName: 'Nume',
        email: 'Adresa e-mail',
        phone: 'Numar Telefon',
        address: 'Adresa de livrare pentru pachet',
        addressAdditional: 'Informatii suplimentare adresă',
        city: 'Oras',
        zip: 'Cod Postal',
        country: 'Tara',
        submit: 'Trimiteți',
        choose: 'Alege',
        nc: 'ALTELE (numărul meu de lot nu este pe această listă)',
      },
      errors: { requiredField: 'Acest câmp este obligatoriu' },
      confirmations: {
        good: {
          title: 'Casca ta nu este afectată.',
          p1: 'Confirmăm că produsul dvs. nu este afectat de această retragere, astfel încât să puteți continua să-l utilizați în siguranță.',
          p2: 'Ne cerem scuze pentru orice inconvenient cauzat.',
          p3: 'Cu sportivitate,',
          p4: 'Echipa Decathlon',
        },
        defective: {
          title: 'Casca ta este afectată.',
          p1: 'Vă rugăm să încetați imediat utilizarea acestui produs și să vă aduceți casca la cel mai apropiat magazin Decathlon, pentru a proceda la rambursarea sau înlocuirea produsului.',
          p2: 'Pentru mai multe informații, vă recomandăm să contactați centrul de asistență pentru clienți. Detaliile de contact pot fi găsite în e-mailul primit.',
          p3: 'Pentru a găsi cel mai apropiat Atelier Decathlon:',
          decathlonLocatorLink: 'https://www.decathlon.fr/store-locator',
          p4: 'Veți găsi mai jos "Notificarea de retur client" pentru a o prezenta Tehnicianului nostru când returnați bicicleta la atelier:',
          p5: 'Pentru mai multe informații, vă rugăm să ne contactați la numarul de telefon 021 408 5005 de Luni-Vineri 09h00 la 20h00 și Sâmbăta-Duminica 10h00 la 19h00',
        },
        resetForm: 'Mai am o cască de verificat.',
        exit: 'Ieșire',
        findShop: 'Găsiți magazinul meu',
      },
    },
    findTheSerialNumber: {
      title: 'Unde pot găsi numărul de lot?',
      subTitle: 'Numărul de lot este situat în interiorul căștii, sub căptușeala din față.',
    },
    description2:
      'Pentru a verifica dacă casca dvs. este afectată de acest defect, vă rugăm să accesați linkul de mai jos. Vi se va cere să selectați numărul lotului aferent căștii dvs. și veți fi îndrumat(ă) prin proces.',
  },
}

export default ro
