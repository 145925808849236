// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const nl: RequiredTranslationsTemplate = {
  meta: { localeName: 'Nederlands' },
  home: {
    title: 'Terugroepactie SNEEUWSCHOENEN SH500L BERGSPORT',
    description:
      'U heeft een paar SH500L Montagne-sneeuwschoenen gekocht en daarvoor danken wij u. We hebben een defect geconstateerd aan de schroeven, wat uw voortgang op besneeuwde paden zou kunnen belemmeren. Daarom vragen wij u de volgende gegevens te verstrekken. Er wordt een pakket naar u verzonden, zodat u uw product kunt retourneren aan onze serviceprovider FANDI die verantwoordelijk is voor de reparatie',
    form: {
      title: 'Voer de volgende gegevens in',
      fields: {
        serialNumber: 'Batchnummer',
        serialNumberFamily: 'N° série (début)',
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        email: 'e-mailadres',
        phone: 'Telefoonnummer',
        address: 'Adres',
        addressAdditional: 'Extra adresgegevens',
        city: 'Woonplaats',
        zip: 'Postcode',
        country: 'Land',
        submit: 'Valideren',
        choose: 'Kiezen',
        nc: 'ANDERE (mijn batchnummer staat niet in deze lijst)',
      },
      errors: {
        requiredField: 'Dit veld is verplicht',
        unknownSerialNumber: 'Onbekend serienummer',
        provide6Numbers: '6 cijfers zijn vereist',
      },
      confirmations: {
        good: {
          title: 'Uw helm wordt niet beïnvloed.',
          p1: 'Wij bevestigen dat uw helm niet onder deze terugroepactie valt, zodat u deze in alle veiligheid kunt blijven gebruiken.',
          p2: 'Onze excuses voor het ongemak.',
          p3: 'Met sportieve groet',
          p4: 'Team Decathlon',
        },
        defective: {
          title: 'Uw helm is aangetast.',
          p1: 'Wij verzoeken u deze niet langer te contact op te nemen met de Decathlon-winkel van uw keuze, om uw geld terug te krijgen of te vervangen.',
          p2: 'Voor meer informatie raden wij u aan contact op te nemen met de klantenservice. Deze vindt u in de e-mail die u heeft ontvangen.',
          p3: 'Om de dichtstbijzijnde Decathlon Workshop te vinden, kijk op  :',
          decathlonLocatorLink: 'https://www.decathlon.fr/store-locator',
          p4: 'Hieronder vindt u uw "Klantretourmelding" om aan onze technicus te presenteren wanneer u terugkeert naar de werkplaats:',
          p5: 'Neem voor meer informatie contact op met onze klantenservice op het nummer 02 208 26 60 van maandag tot zaterdag van 9.00 tot 19.00uur klantendienst@decathlon.com',
        },
        'reset-form': 'Ik moet nog een helm controleren',
        exit: 'verlaten',
        'find-shop': 'Vind mijn winkel',
        resetForm: 'Ik moet nog een helm controleren',
        findShop: 'Vind mijn winkel',
      },
    },
    findTheSerialNumber: {
      title: 'Waar kan ik mijn batchnummer vinden?',
      description:
        'Het 30-cijferige nummer staat op de onderkant van uw fietsframe, dicht bij de trapas en pedalen. Zie onderstaande afbeelding.',
      subTitle:
        'Het batchnummer vindt u aan de binnenkant van de helm, onder het schuim aan de voorkant van de helm.',
    },
    description2:
      'Alleen bepaalde lotnummers, van bepaalde kleuren en maten, worden beïnvloed. Wij nodigen u uit om het batchnummer te selecteren dat overeenkomt met het nummer dat op uw helm staat vermeld, om erachter te komen of uw helm onder deze terugroepactie valt:',
  },
}

export default nl
