import { RequiredTranslationsTemplate } from '.'

const it: RequiredTranslationsTemplate = {
  meta: { localeName: 'Italiano' },
  home: {
    title: 'RICHIAMO PRODOTTO: CIASPOLE SH500L MOUNTAIN ',
    description:
      'Ha acquistato un paio di ciaspole SH500L Mountain e La ringraziamo per questo. Abbiamo identificato un difetto nelle viti che potrebbe ostacolare la Sua progressione sui sentieri innevati. La preghiamo quindi di fornirci i seguenti dati. Le verrà inviato un pacco in modo che possa restituire il prodotto al nostro fornitore FANDI responsabile della riparazione',
    form: {
      title: 'Grazie di inserire le seguenti informazioni',
      fields: {
        firstName: 'Nome',
        lastName: 'Cognomne',
        email: 'Indirizzo email',
        phone: 'Numero di telefono',
        address: 'Indirizzo per la consegna del pacco',
        addressAdditional: "Dettagli aggiuntivi per l'indirizzo di consegna",
        city: 'Città',
        zip: 'CAP',
        country: 'Paese',
        serialNumber: 'Numero di lotto',
        choose: 'Selezionare',
        nc: 'Altro (il mio numero di lotto non è presente in questa lista)',
        submit: 'Validare',
      },
      errors: { requiredField: 'Questo campo è obbligatorio' },
      confirmations: {
        defective: {
          title: 'Il vostro casco è interessato dal Richiamo',
          p1: 'Vi chiediamo di non utilizzarlo più e di recarvi nel negozio Decathlon a voi più vicino con il vostro casco al fine di procedere ad un rimborso o ad una sostituzione.',
          p2: 'Per maggiori informazioni vi invitiamo a rivolgervi al Servizio Clienti tramite i contatti presenti nella mail che avete ricevuto',
        },
        good: {
          title: 'Il vostro casco NON è interessato dal Richiamo',
          p1: 'Vi confermiamo che il vostro casco non è interessato da questa campagna di Richiamo. Potete quindi continuare ad utilizzarlo.',
          p2: "Ci scusiamo per l'inconveniente causato.",
          p3: 'Sportivamente',
          p4: 'La squadra Decathlon',
        },
        resetForm: 'Ho un altro casco da verificare',
        exit: 'Chiudere',
        findShop: 'Ricerca negozio',
      },
    },
    findTheSerialNumber: {
      title: 'Dove trovare il numero di lotto?',
      subTitle:
        "Il numero di lotto si trova all'interno del casco, sotto la calotta in tessuto nella parte anteriore",
    },
    description2:
      'Sono interessati solo determinati numeri di lotto, di determinati colori e dimensioni. Ti invitiamo ad indicare di seguito il numero di lotto corrispondente a quello indicato sul tuo casco per scoprire se è interessato da questa campagna di richiamo',
  },
}

export default it
