// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const de: RequiredTranslationsTemplate = {
  meta: { localeName: 'Deutsch' },
  home: {
    title: 'Produktrückruf Schneeschuhe SH500L Mountain',
    description:
      'Wir danken dir für den Kauf der Schneeschuhe SH500 Mountain. Wir haben allerdings einen Defekt an den Schrauben festgestellt, der dein Vorankommen auf verschneiten Wegen behindern könnte. Bitte gib daher die folgenden Informationen an. Du erhältst dann ein Paket, mit dem du dein Produkt an unseren für die Reparatur zuständigen Dienstleister zurücksenden kannst.',
    form: {
      title: 'Bitte gib die folgenden Informationen ein:',
      fields: {
        serialNumber: 'Chargennummer',
        serialNumberFamily: 'N° série (début)',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail Adresse',
        phone: 'Telefonnummer',
        address: 'Anschrift',
        addressAdditional: 'Adresszusatz',
        city: 'Stadt',
        zip: 'Postleitzahl',
        country: 'Land',
        submit: 'Bestätigen',
        choose: 'Auswählen',
        nc: 'ANDERE (meine Chargennummer ist nicht in dieser Liste enthalten).',
      },
      errors: {
        requiredField: 'Dieses Feld muss ausgefüllt werden.',
        unknownSerialNumber: 'Unbekannte Seriennummer',
        provide6Numbers: 'Achtung, 6 Ziffern sind erforderlich',
      },
      confirmations: {
        good: {
          title: 'Dein Helm ist nicht betroffen.',
          p1: 'Wir bestätigen dir, dass dein Helm nicht von diesem Rückruf betroffen ist, sodass du ihn weiterhin sicher verwenden kannst.',
          p2: 'Wir entschuldigen uns für die entstandenen Unannehmlichkeiten.',
          p3: 'Mit sportlichen Grüßen,',
          p4: 'Dein DECATHLON Team',
        },
        defective: {
          title: 'Dein Helm ist betroffen.',
          p1: 'Wir bitten dich, das Produkt nicht mehr zu benutzen und mit deinem Helm in eine DECATHLON Filiale deiner Wahl zu gehen, um ihn erstatten oder ersetzen zu lassen.',
          p2: 'Für weitere Informationen empfehlen wir dir, dich an unseren Kundenservice zu wenden, den du in der E-Mail findest, die du erhalten hast.',
          p3: 'Hier kannst du einen Servicepoint in deiner Nähe finden:',
          decathlonLocatorLink: 'https://www.decathlon.de/store-locator',
          p4: 'Und hier findest du den Reparaturschein, den du bitte am Servicepoint für die Reparatur vorlegst:',
          p5: 'Unser Kundendienst steht dir von Montag bis Freitag von 9:00 bis 17:00 Uhr und Samstag von 10:00 bis 18:00 Uhr unter der Rufnummer +49 (0) 6202 97 81 300 für Fragen jeglicher Art zur Verfügung. Wir bedanken uns für dein Verständnis und entschuldigen uns für die Unannehmlichkeiten.',
        },
        'reset-form': 'Ich möchte einen anderen Helm überprüfen',
        exit: 'Verlassen',
        'find-shop': 'Meine Filiale finden',
        resetForm: 'Ich möchte einen anderen Helm überprüfen',
        findShop: 'Meine Filiale finden',
      },
    },
    findTheSerialNumber: {
      title: 'Wo finde ich meine Chargennummer?',
      description: 'Die Seriennummer befindet sich unten am Rahmen, auf Höhe der Pedale.',
      subTitle:
        'Die Chargennummer befindet sich im Inneren des Helms unter dem Schaumstoff im vorderen Bereich.',
    },
    description2:
      'Nur bestimmte Chargennummern, Farben und Größen sind davon betroffen. Bitte wähle die Chargennummer, die auf deinem Helm angegeben ist, um herauszufinden, ob dein Produkt von diesem Rückruf betroffen ist:',
  },
}

export default de
