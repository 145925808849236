import { FieldProps } from 'formik'
import React from 'react'
import { Form } from 'react-bulma-components'
import FormField, { FormFieldProps } from './form-field'

export interface InputFieldProps extends FormFieldProps, FieldProps {
  type: string
  showLabel?: boolean
}

const InputField: React.FunctionComponent<InputFieldProps> = props => {
  const { field, form, label, help, showLabel = true, ...rest } = props
  if (props.field.value === undefined) props.field.value = ''
  return (
    <FormField
      label={label}
      help={help}
      error={form.errors[field.name] as string}
      showLabel={showLabel}
    >
      <Form.Input {...field} {...rest} />
    </FormField>
  )
}

export default InputField
