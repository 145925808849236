// @ts-nocheck
import { RequiredTranslationsTemplate } from '.'

const cs: RequiredTranslationsTemplate = {
  meta: { localeName: 'Čeština' },
  home: {
    title: 'STAŽENÍ PRODUKTU SNĚŽNICE SH500L MOUNTAIN',
    description:
      'Zakoupili jste si pár sněžnic SH500L Mountain a my vám za to děkujeme. Zjistili jsme závadu na šroubech, která by mohla bránit vašemu postupu na zasněžených stezkách. Proto prosím uveďte následující podrobnosti. Bude vám zaslán balíček, abyste mohli svůj produkt vrátit našemu poskytovateli služeb odpovědnému za opravu.',
    form: {
      title: 'Vyplňte prosím následující údaje',
      fields: {
        serialNumber: 'Číslo šarže  ',
        serialNumberFamily: 'N° série (début)',
        firstName: 'Jméno',
        lastName: 'Příjmení',
        email: 'E-mail ',
        phone: 'Telefonní číslo',
        address: 'Adresa pro doručení balíčku',
        addressAdditional: 'Další podrobnosti o adrese',
        city: 'Město',
        zip: 'PSČ',
        country: 'Země',
        submit: 'Potvrdit',
        choose: 'Vybrat',
        nc: 'JINÉ (mé číslo šarže není v tomto seznamu)',
      },
      errors: {
        requiredField: 'Toto pole je povinné',
        unknownSerialNumber: 'Neznámé sériové číslo',
        provide6Numbers: 'Upozorňujeme, že je vyžadováno 6 číslic',
      },
      confirmations: {
        good: {
          title: 'Stažení z prodeje se Vaší helmy netýká. ',
          p1: 'Můžeme potvrdit, že Vaše helma není tímto stažením z prodeje dotčena, takže ji můžete nadále bezpečně používat.',
          p2: 'Omlouváme se za vzniklé nepříjemnosti. ',
          p3: 'Se sportovním pozdravem',
          p4: 'tým Decathlon',
        },
        defective: {
          title: 'Stažení z prodeje se Vaší helmy týká.',
          p1: 'Žádáme Vás, abyste helmu přestali používat a donesli ji na Vámi zvolenou prodejnu Decathlon, kde Vám budou vráceny peníze nebo provedena výměna.',
          p2: 'Pro více informací se prosím obraťte na zákaznické centrum. Kontakt na něj je uveden v e-mailu, který jste obdrželi. ',
          p3: 'Zde najdete nejbližší servis:',
          decathlonLocatorLink: 'https://www.decathlon.fr/store-locator',
          p4: 'Níže naleznete dokument, který předložte na oddělení servisu:',
          p5: 'Pro více informací kontaktujte naše zákaznické centrum: +420 228 800 008 od pondělí do pátku od 9:00 a 19:00 hodin, o víkendu od 9:00 do 17:00 hodin.',
        },
        'reset-form': 'Chci ověřit další helmu na horské kolo',
        exit: 'Odejít',
        'find-shop': 'Vyhledat prodejnu',
        resetForm: 'Chci ověřit další helmu na horské kolo',
        findShop: 'Vyhledat prodejnu',
      },
    },
    findTheSerialNumber: {
      title: 'Kde na své helmě najdu číslo šarže?',
      description: 'Sériové číslo je umístěno na vidlici',
      subTitle: 'Číslo šarže najdete na vnitřní straně přilby, v přední části pod pěnou.',
    },
    description2:
      'Toto stažení z prodeje se týká pouze helem s určitými čísly šarží a v určitých barvách a velikostech. Vyberte prosím číslo šarže, které se shoduje s číslem uvedeným na Vaší helmě. Tak zjistíte, zda se toto stažení z prodeje týká i Vaší helmy:',
  },
}

export default cs
