import React from 'react'
import {
  Button,
  Columns,
  Container,
  Element,
  Heading,
  Image,
  Section,
} from 'react-bulma-components'
import Layout from '../components/layout/layout'
import CheckStatusForm from '../components/product-items/check-status-form'
import { useCheckSerialNumberStatusMutation } from '../queries'
// @ts-expect-error
import snowshoesImage from '../assets/images/snowshoes.jpg'
// @ts-expect-error
import logo from '../assets/images/logo.png'
import { Translate } from '../utils/translations'
import { BiCheckCircle } from 'react-icons/bi'

const HomePage: React.FC = () => {
  const mutation = useCheckSerialNumberStatusMutation()

  return (
    <Layout>
      <Image src={logo} style={{ maxWidth: 300, margin: 'auto' }} />
      <Section pb={2}>
        <Container>
          <Heading size={2} textAlign="center" mb={6}>
            <Translate value="home.title" />
          </Heading>
          <Image src={snowshoesImage} style={{ maxWidth: 320, margin: 'auto' }} mb={5} />
          {!mutation.isSuccess && (
            <Columns centered>
              <Columns.Column size={10}>
                <Element renderAs="p" mt={5} textSize={5} textAlign="center">
                  <Translate value="home.description" />
                </Element>
              </Columns.Column>
            </Columns>
          )}
        </Container>
      </Section>
      <Section mb={6}>
        <Container>
          {mutation.isSuccess ? (
            <>
              <Element textAlign={'center'} style={{ maxWidth: 800, margin: 'auto' }}>
                <Element style={{ fontSize: 60 }} textColor="success">
                  <BiCheckCircle />
                </Element>
                <Heading size={3}>Thank you for your time!</Heading>
                <Button renderAs="a" href="https://www.decathlon.fr/" mt={3}>
                  <Translate value="home.form.confirmations.exit"></Translate>
                </Button>
              </Element>
            </>
          ) : (
            <Element>
              <Heading size={3} mb={6} pb={3} textAlign="center">
                <Translate value="home.form.title" />
              </Heading>
              <CheckStatusForm onSubmit={mutation.mutate} />
            </Element>
          )}
          {/* {mutation.error?.code === 404 && <Element>Unknown doc</Element>}
          {mutation.data?.status === 'good' && <Element>Good item</Element>}
          {mutation.data?.status === 'defective' && <Element>Bad item</Element>} */}
        </Container>
      </Section>
    </Layout>
  )
}

export default HomePage
